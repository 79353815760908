import { ROUTE_TREATMENT_DETAIL, route } from "../../../core/routing/routing";

import FadeInUp from "../animations/fadeInUp";
import { Link } from "react-router-dom";
import React from "react";
import Tilt from "react-tilt";
import VisibilitySensor from "react-visibility-sensor";
import { renderHtml } from '../../../core/helpers';
import { t } from "../../../core/localization/source";

export default function TreatmentsBlock({ className }) {
  const startDelay = 1000;
  const delayInterval = 100;
  return (
    <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (
        <ul className={`treatments-block ${className}`}>
          <Link
            className="treatment col-sm-6 col-lg-3 mb-5 text-decoration-none"
            to={{
              pathname: route(ROUTE_TREATMENT_DETAIL, {
                ":treatment": t("treatments.reeducation.route")
              }),
              state: { foo: "bar" }
            }}
          >
            <FadeInUp delay={startDelay} once fadeIn={isVisible ? true : false}>
              <Tilt options={{ scale: 1 }}>
                <li className="d-flex flex-column justify-content-between">
                  <div>
                    <h2 className="smaller" dangerouslySetInnerHTML={renderHtml(t("treatments.reeducation"))}></h2>
                    <p>{t("treatments.reeducation.summary")}</p>
                  </div>
                  <span>
                    <strong>{t("general.read_more")}</strong>
                  </span>
                </li>
              </Tilt>
            </FadeInUp>
          </Link>
          <Link
            className="treatment col-sm-6 col-lg-3 mb-5 text-decoration-none"
            to={route(ROUTE_TREATMENT_DETAIL, {
              ":treatment": t("treatments.pregnancy.route")
            })}
          >
            <FadeInUp
              delay={startDelay + delayInterval}
              once
              fadeIn={isVisible ? true : false}
            >
              <Tilt options={{ scale: 1 }}>
                <li className="d-flex flex-column justify-content-between">
                  <div>
                    <h2 className="smaller" dangerouslySetInnerHTML={renderHtml(t("treatments.pregnancy"))}></h2>
                    <p>{t("treatments.pregnancy.summary")}</p>
                  </div>
                  <span>
                    <strong>{t("general.read_more")}</strong>
                  </span>
                </li>
              </Tilt>
            </FadeInUp>
          </Link>
          <Link
            className="treatment col-sm-6 col-lg-3 mb-5 text-decoration-none"
            to={route(ROUTE_TREATMENT_DETAIL, {
              ":treatment": t("treatments.lymphe.route")
            })}
          >
            <FadeInUp
              delay={startDelay + 2 * delayInterval}
              once
              fadeIn={isVisible ? true : false}
            >
              <Tilt options={{ scale: 1 }}>
                <li className="d-flex flex-column justify-content-between">
                  <div>
                    <h2 className="smaller" dangerouslySetInnerHTML={renderHtml(t("treatments.lymphe"))}></h2>
                    <p>{t("treatments.lymphe.summary")}</p>
                  </div>
                  <span>
                    <strong>{t("general.read_more")}</strong>
                  </span>
                </li>
              </Tilt>
            </FadeInUp>
          </Link>
          <Link
            className="treatment col-sm-6 col-lg-3 mb-5 text-decoration-none"
            to={route(ROUTE_TREATMENT_DETAIL, {
              ":treatment": t("treatments.kine_revalidation.route")
            })}
          >
            <FadeInUp
              delay={startDelay + 3 * delayInterval}
              once
              fadeIn={isVisible ? true : false}
            >
              <Tilt options={{ scale: 1 }}>
                <li className="d-flex flex-column justify-content-between">
                  <div>
                    <h2 className="smaller" dangerouslySetInnerHTML={renderHtml(t('treatments.kine_revalidation'))}></h2>
                    <p>{t("treatments.kine_revalidation.summary")}</p>
                  </div>
                  <span>
                    <strong>{t("general.read_more")}</strong>
                  </span>
                </li>
              </Tilt>
            </FadeInUp>
          </Link>
        </ul>
      )}
    </VisibilitySensor>
  );
}
