import React from "react";
import "./index.css";
import App from "./App";
import "./style/app.scss";
import { toggleNavbar } from "./core/navigation/main";
import { hydrate, render } from "react-dom";
import * as serviceWorker from "./serviceWorker";

// toggleNavbar();

// ReactDOM.render(<App />, document.getElementById("root"));

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();

// import React from "react";
// import "./index.css";
// import App from "./App";
// import { hydrate, render } from "react-dom";
// import "./style/app.scss";
// import * as serviceWorker from "./serviceWorker";
// import { toggleNavbar } from "./core/navigation/main";

toggleNavbar();

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}
serviceWorker.unregister();
