import React, { Fragment } from "react";
import { t } from "../../../core/localization/source";
import FadeInUp from "../../shared/animations/fadeInUp";
import VisibilitySensor  from 'react-visibility-sensor';

export default function IntroBlock() {
  return (
    <Fragment>
        <VisibilitySensor partialVisibility={true}>
            {({isVisible}) =>
              <Fragment>
              <div className="col-md-6 mb-5 mb-md-0">
                <FadeInUp once fadeIn={isVisible ? true : false}>
                  <div className="mb-5 pb-2">
                    <h3>{t("introduction.practical.appointment")}</h3>
                    <p>{t("introduction.practical.appointment.description")}</p>
                  </div>
                </FadeInUp>
                <FadeInUp once delay='500' fadeIn={isVisible ? true : false}>
                  <div className="mt-5 pt-2">
                    <h3>{t("introduction.practical.prices")}</h3>
                    <p>{t("introduction.practical.prices.description")}</p>
                  </div>
                </FadeInUp>
                </div>
              </Fragment>
            }
        </VisibilitySensor>
        <div className="col-md-5 offset-sm-1 d-flex align-items-center">
          <VisibilitySensor partialVisibility={true}>
          {({isVisible}) =>
              <FadeInUp once delay='300' fadeIn={isVisible ? true : false}>
                <div className="position-relative">
                  <p className="quote dimmed">{t("introduction.quote")}</p>
                </div>
              </FadeInUp>
            }
          </VisibilitySensor>
        </div>
    </Fragment>
  );
}
