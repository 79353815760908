import React, { Component } from "react";
import posed from "react-pose";

const Box = posed.div({
  hidden: {
    opacity: 0,
    x: 50,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      opacity: { ease: "linear", duration: 500 },
      default: { ease: "easeInOut", duration: 1000 }
    }
  }
});

class FadeInRight extends Component {
  state = { visibility: false };

  componentDidUpdate = (prevProps, prevState) => {
    if(prevProps !== this.props){
      if(this.props.fadeIn === true){
        this.changeState(true)
      } else if(!this.props.once) {
        this.changeState(false)
      }
    }
  }

  componentWillUnmount = () => {
    clearTimeout(this.timeoout);
  }

  changeState = (state) => {
    let delay = 100;
    if(this.props.delay){
      delay = this.props.delay;
    }

    this.timeoout = setTimeout(() => {
      this.setState({
        visibility: state
      })
    }, delay);
  }

  render() {
    const { visibility } = this.state;
    return (
        <Box className="box" pose={visibility ? "visible" : "hidden"}>
          {this.props.children}
        </Box>
    );
  }
}

export default FadeInRight;
