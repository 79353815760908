import { ROUTE_TREATMENT_DETAIL, route } from "../../../core/routing/routing";
import React, { Component, Fragment } from "react";

import FadeInLeft from "../../shared/animations/fadeInLeft";
import FadeInOut from "../../shared/animations/fadeInOut";
import FadeInRight from "../../shared/animations/fadeInRight";
import FadeInUp from "../../shared/animations/fadeInUp";
import { Link } from "react-router-dom";
import Tilt from 'react-tilt';
import VisibilitySensor from "react-visibility-sensor";
import { renderHtml } from '../../../core/helpers';
import { t } from "../../../core/localization/source";

const assignClass = index => {
  switch (index) {
    case 0:
      return "first";
    case 1:
      return "second";
    case 2:
      return "third";
    case 3:
      return "fourth";

    default:
      break;
  }
};
export default class TreatmentDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      animate: false,
      activeTreatmentIndex: 0,
      treatments: [
        "reeducation",
        "pregnancy",
        "lymphe",
        "kine_revalidation"
      ],
      treatmentName: ''
    }
  }

  componentDidMount = () => {
    this.setInfo();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.timeoout = setTimeout(() => {
        this.setInfo();
      }, 350);
    }
  }

  componentWillUnmount = () => {
    clearTimeout(this.timeoout);
  }

  fadeOutAndIn = () => {
    this.setState({
      animate: true,
    }, () => {
      setTimeout(() => {
        this.setState({
          animate: false,
        })
      }, 700)
    })
  }

  selectTreatment = () => {
    switch (this.props.match.url) {
      case route(ROUTE_TREATMENT_DETAIL, {
        ":treatment": t("treatments.reeducation.route")
      }):
        return 0;
      case route(ROUTE_TREATMENT_DETAIL, {
        ":treatment": t("treatments.pregnancy.route")
      }):
        return 1;
      case route(ROUTE_TREATMENT_DETAIL, {
        ":treatment": t("treatments.lymphe.route")
      }):
        return 2;
      case route(ROUTE_TREATMENT_DETAIL, {
        ":treatment": t("treatments.kine_revalidation.route")
      }):
        return 3;
      default:
        return 0;
    }
  }

  setInfo = () => {
    let index = this.selectTreatment();

    this.setState({
      activeTreatmentIndex: index,
      treatmentName: this.state.treatments[index],
    })
  }

  render() {
    return (
      <FadeInOut animate={this.state.animate}>
        <section className="row m-0 pt-5 mb-5 pb-5 position-relative text-primary treatment-detail-page first-section">
          <div className="container pb-5 mb-5">
            <div className="row">
              <div className="col-md-6">
                <VisibilitySensor partialVisibility={true}>
                  {({ isVisible }) => (
                    <Fragment>
                      <FadeInLeft delay="150" once fadeIn={isVisible ? true : false}>
                        <h1>
                          <span dangerouslySetInnerHTML={renderHtml(t(`treatments.${this.state.treatmentName}`))}></span>
                        </h1>
                      </FadeInLeft>
                      <FadeInLeft delay="1200" once fadeIn={isVisible ? true : false}>
                        <h2 className="smaller yellow">{t(`treatments.all`)}</h2>
                      </FadeInLeft>
                      <ul className="treatment-list">
                        {this.state.treatments.map((treatment, index) => {
                          return (
                            <FadeInLeft once key={index} delay={1200 + index * 100} fadeIn={isVisible ? true : false}>
                              <div className={this.state.activeTreatmentIndex === index ? 'active' : ''}>
                                <li className={
                                  assignClass(index)
                                }>
                                  <Link
                                    to={route(ROUTE_TREATMENT_DETAIL, {
                                      ":treatment": t(`treatments.${treatment}.route`)
                                    })}
                                    onClick={() => this.fadeOutAndIn()}
                                    dangerouslySetInnerHTML={renderHtml(t(`treatments.${treatment}`))}
                                    className="link-unstyled"
                                  >
                                  </Link>
                                </li>
                              </div>
                            </FadeInLeft>
                          );
                        })}
                      </ul>
                      <FadeInUp
                        delay={1000}
                        className={`treatment-image mt-5 mt-md-0 ${this.state.treatmentName}`}
                        fadeIn={true}
                      />
                    </Fragment>
                  )}
                </VisibilitySensor>
              </div>
              <VisibilitySensor partialVisibility={true}>
                {({ isVisible }) => (
                  <div className="col-md-6 explanation">
                    <FadeInRight delay={300} fadeIn={true}>
                      <Tilt options={{ max: 15, scale: 1.05, perspective: 2000, }}>
                        <div className="card p-5">
                          <h2 className="smaller mb-4">
                            {t(`treatments.${this.state.treatmentName}.title`)}
                          </h2>
                          <p>{t(`treatments.${this.state.treatmentName}.description`)}</p>
                          {t(`treatments.${this.state.treatmentName}.second_title`) && (
                            <h2 className="smaller mb-4 mt-5">
                              {t(`treatments.${this.state.treatmentName}.second_title`)}
                            </h2>
                          )}
                          {t(`treatments.${this.state.treatmentName}.second_description`) && (
                            <p>
                              {t(`treatments.${this.state.treatmentName}.second_description`)}
                            </p>
                          )}
                          {t(`treatments.${this.state.treatmentName}.third_title`) && (
                            <h2 className="smaller mb-4 mt-5">
                              {t(`treatments.${this.state.treatmentName}.third_title`)}
                            </h2>
                          )}
                          {t(`treatments.${this.state.treatmentName}.third_description`) && (
                            <p>
                              {t(`treatments.${this.state.treatmentName}.third_description`)}
                            </p>
                          )}
                        </div>
                      </Tilt>
                    </FadeInRight>
                  </div>
                )}
              </VisibilitySensor>
            </div>
          </div>
        </section>
      </FadeInOut>
    );
  }
}
