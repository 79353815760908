import React, { Component } from "react";

import FadeInLeft from "../../shared/animations/fadeInLeft";
import FadeInUp from "../../shared/animations/fadeInUp";
import TreatmentsBlock from "../../shared/treatment/TreatmentsBlock";
import VisibilitySensor from "react-visibility-sensor";
import { renderHtml } from '../../../core/helpers';
import { t } from "../../../core/localization/source";

export default class TreatmentOverview extends Component {
  render() {
    return (
      <section className="row m-0 pt-5 mb-5 pb-5 position-relative text-primary treatment-page first-section">
        <div className="container">
          <div className="row flex-column-reverse flex-md-row">
            <div className="col-md-6">
              <div className="position-relative">
                <VisibilitySensor partialVisibility={true}>
                  {({ isVisible }) => (
                    <FadeInLeft
                      delay={800}
                      once
                      fadeIn={isVisible ? true : false}
                    >
                      <p className="quote dimmed yellow">
                        <span>{t("treatments.quote")}</span>
                      </p>
                    </FadeInLeft>
                  )}
                </VisibilitySensor>
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-end">
              <VisibilitySensor>
                {({ isVisible }) => (
                  <FadeInUp once fadeIn={isVisible ? true : false}>
                    <h1 dangerouslySetInnerHTML={renderHtml(t("treatments.title"))}></h1>
                  </FadeInUp>
                )}
              </VisibilitySensor>
            </div>
          </div>
          <div className="row">
            <TreatmentsBlock className="dark" />
          </div>
        </div>
      </section>
    );
  }
}
