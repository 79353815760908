import React, { Component } from "react";

const divStyle = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  height: "50vh",
  margin: "20px",
  textAlign: "center"
};

export default class NoMatch extends Component {
  render() {
    return (
      <div style={divStyle}>
        <h1>'404'</h1>
        <div>
          <h2>Oeps! Pagina niet gevonden...</h2>
        </div>
      </div>
    );
  }
}
