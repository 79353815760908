import React from "react";
import { ReactComponent as CurveSecondary } from "../../assets/curve-secondary.svg";
import { ReactComponent as CurveMobile } from "../../assets/curve-mobile.svg";
import { ReactComponent as Curve } from "../../assets/curve.svg";
import { t } from "../../core/localization/source";
import VisibilitySensor from "react-visibility-sensor";
import FadeInUp from "../shared/animations/fadeInUp";

export default function About() {
  return (
    <section className="third-section">
      <div className="about-bg-image desktop d-none d-lg-flex" />
      <Curve
        preserveAspectRatio="xMaxYMax meet"
        className="d-none d-lg-block about-mid-curve"
      />
      <CurveSecondary className="fill-primary about-top-curve" />
      <div className="container">
        <div className="row m-0 mt-5 mb-5 pb-0 pb-lg-5">
          <div className="col-lg-5">
            <VisibilitySensor partialVisibility={true}>
              {({ isVisible }) => (
                <FadeInUp once delay="0" fadeIn={isVisible ? true : false}>
                  <h1 className="mb-4">{t("about.title")}</h1>
                  <p>{t("about.description")}</p>
                </FadeInUp>
              )}
            </VisibilitySensor>
          </div>
        </div>
      </div>
      <div className="about-bg-image mobile d-lg-none">
        <CurveMobile className="fill-white about-mobile-curve" />
      </div>
    </section>
  );
}
