import React, { Component } from "react";
import posed from "react-pose";

const Box = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      y: { ease: 'easeInOut' },
      default: { duration: 300 }
    }
  },
  exit: {
    y: 50,
    opacity: 0,
    transition: { duration: 300 }
  }
});

class FadeInOut extends Component {
  constructor(props) {
    super(props);
    this.state = { isVisible: true };
  }

  componentDidUpdate(prevProps) {
    if((prevProps.animate !== this.props.animate) ) {
      this.changeState(this.props.animate)
    }
  }


  changeState = (visibility) => {
    this.setState({
      isVisible: !visibility,
    })
  }


  render() {
    const { isVisible } = this.state;
    return (
        <Box className="box" pose={isVisible ? "enter" : "exit"}>
          {this.props.children}
        </Box>
    );
  }
}

export default FadeInOut;
