export default {
  "general.read_more": "FR-Lees meer",

  "home.intro.first-line": "FR-Kinesitherapie, voor iedereen.",
  "home.intro.second-line": "FR-Céline Charlier.",
  "home.intro.button.left": "FR-Maak een afspraak",
  "home.intro.button.right": "FR-Ontdek mijn behandelingen",

  "navigation.home": "FR-Home",
  "navigation.treatments": "FR-Behandelingen",
  "navigation.contact": "FR-Contact",
  "contact.phone": "FR-+32 471 87 18 93",
  "contact.street": "FR-Kerkplein 15, 9600 Ronse",
  "contact.mail": "FR-celine@fitfocus.be",

  "treatments.title": "FR-Mijn behandelingen",
  "treatments.kine_revalidation": "FR-Kinesitherapie & revalidatie",
  "treatments.pregnancy": "FR-Zwangerschapstherapie",
  "treatments.reeducation": "FR-Bekkenbodemreëducatie",
  "treatments.lymphe": "FR-Lymfedrainage",
  "treatments.quote":
    "FR-Als kinesitherapist specialiseerde ik me in een aantal gebieden waar uw zorg steeds centraal staat.",

  "treatments.kine_revalidation.summary":
    "FR - Optimale genezing nastreven door individuele begeleiding naargelang de capaciteit van de patiënt.",
  "treatments.pregnancy.summary":
    "FR - Perinatale begeleiding is belangrijk om de periode rond de bevalling en geboorte zo aangenaam mogelijk te beleven.",
  "treatments.reeducation.summary":
    " FR - Bekkenbodemkine is een methode waarbij u de spieren van de bekkenbodem leert te voelen en bewust te gebruiken, zowel ter versterking als ter relaxatie.",
  "treatments.lymphe.summary":
    " FR - Lymfedrainage is een zachte massagetechniek met als doel het teveel aan vocht beter en sneller via de de lymfbanen af te voeren.",
  "introduction.practical": "FR-Praktische info",
  "introduction.practical.appointment": "FR-Afspraken",
  "introduction.practical.appointment.description":
    "FR - Behandelingen gebeuren uitsluitend op afspraak. U kan telefonisch een afspraak maken op het nummer 0471/87.18.93 of mailen naar info@fitfocus.be. Gelieve steeds tijdig aanwezig te zijn op uw afspraak. Indien u te laat bent kan de behandeling niet langer duren. Bij het niet afbellen van de afspraak, zal de consultatie aangerekend worden.",
  "introduction.practical.prices": "FR-Tarieven",
  "introduction.practical.prices.description":
    "FR - Als geconventioneerd kinesitherapeut wordt er gewerkt met de officiële tarieven die het RIZIV oplegt. De terugbetaling verloopt via uw mutualiteit, hoeveel u terugkrijgt hangt af van uw ziektebeeld en uw sociaal statuut.",
  "introduction.quote":
    "FR - Samen zoeken we naar een optimale behandeling om jouw probleem op te lossen.",
  "about.title": "FR-Wie is Céline Charlier?",
  "about.description":
    "FR - Ik ben in 2008 afgestudeerd als licentiaat/master in de revalidatiewetenschappen en kinesitherapie aan de Universiteit Gent.\r\n\nNaast het regelmatig volgen van congressen en bijscholingen, sta ik samen met 3 andere collega's en tevens bestuurleden van de lokale kinekring Ronse - Oudenaarde ( AXXON) in voor het organiseren van navormingen en LOKK's in onze regio.\r\n\nHoewel mijn praktijk een algemene kinesitherapiepraktijk is, is mijn interesse doorheen de jaren gegroeid voor bekkenbodemproblematiek, lymfedrainage en perinatale begeleiding.\r\n\nDoor mijn eigen interesse in sport en beweging, probeer ik ook een aangename sfeer te creëren voor mensen die onder persoonlijke begeleiding wensen te bewegen.",

  "footer.navigation": "FR-Navigatie",
  "footer.home": "FR-Home",
  "footer.treatments": "FR-Behandelingen",
  "footer.contact": "FR-Contact",
  "footer.number.title": "FR-Ondernemingsnummer",
  "footer.number": "FR-0826 203 636",
  "footer.riziv.title": "FR-RIZIV",
  "footer.riziv": "FR-537 194 89 527"
};
