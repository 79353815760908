import { GoogleApiWrapper, InfoWindow, Map, Marker } from "google-maps-react";

import CustomMarker from "../../../assets/icons/map-pin.svg";
import { MyStyle } from "./MyStyle";
import React from "react";
import { t } from "../../../core/localization/source";

export class GoogleMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showingInfoWindow: true,
      activeMarker: {},
      selectedPlace: {}
    };
    // binding this to event-handler functions
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.onMapClick = this.onMapClick.bind(this);
  }
  onMarkerClick = (props, marker, e) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });
  };
  onMapClick = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };
  render() {
    return (
      <div className="map-container">
        <Map
          google={this.props.google}
          zoom={16}
          onClick={this.onMapClick}
          initialCenter={{ lat: 50.74572, lng: 3.60218 }}
          disableDefaultUI={true}
          zoomControl={false}
          mapTypeControl={false}
          scaleControl={false}
          streetViewControl={false}
          rotateControl={false}
          fullscreenControl={false}
          styles={MyStyle}
        >
          <Marker
            onClick={this.onMarkerClick}
            title={"Kinesitherapeute Céline Charlier"}
            position={{ lat: 50.745073, lng: 3.6008427 }}
            name={"Kinesitherapeute Céline Charlier"}
            icon={{ url: CustomMarker }}
          />
          <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}
          >
            <div className="info-window">
              <div variant="headline" component="h4">
                <strong>Kinesitherapeute Céline Charlier</strong>
              </div>
              <div component="p">
                <br />
                <a
                  href="https://goo.gl/maps/FW6JFCAf5tPSc5w86"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-unstyled"
                >
                  {t("contact.street")}
                </a>
                <br />
                <br />
                <a
                  href={`tel:${t("contact.phone")}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-unstyled"
                >
                  {t("contact.phone")}
                </a>
                <br />
                <br />
                <a
                  href={`mailto:${t("contact.mail")}`}
                  className="link-unstyled"
                >
                  {t("contact.mail")}
                </a>
                <br />
              </div>
            </div>
          </InfoWindow>
        </Map>
      </div>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyBmN4KSdqzbDhI2tsZ5zFn-kzyduQBOsy4"
})(GoogleMap);
