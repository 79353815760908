import React, { Component } from "react";
import posed from "react-pose";

const Box = posed.div({
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      opacity: { ease: "linear", duration: 300 },
    }
  }
});

class FadeIn extends Component {
  state = { visibility: false };

  componentDidMount = () => {
    this.changeState(true);
  }

  componentWillUnmount = () => {
    clearTimeout(this.timeoout);
  }

  changeState = (state) => {
    let delay = 1000;
    if(this.props.delay){
      delay = this.props.delay;
    }

    this.timeoout = setTimeout(() => {
      this.setState({
        visibility: state
      })
    }, delay);
  }

  render() {
    const { visibility } = this.state;
    return (
        <Box className="box" pose={visibility ? "visible" : "hidden"}>
          {this.props.children}
        </Box>
    );
  }
}

export default FadeIn;
