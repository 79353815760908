import React from "react";
import { ReactComponent as MailIcon } from "../../../assets/icons/mail.svg";
import { ReactComponent as NavigationIcon } from "../../../assets/icons/navigation.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/icons/phone.svg";
import { ReactComponent as PinIcon } from "../../../assets/icons/pin.svg";
import { ReactComponent as QuoteIcon } from "../../../assets/icons/quote.svg";
import { ReactComponent as Logo } from "../../../assets/icons/logo.svg";

export default function Icon({ name }) {
  switch (name) {
    case "mail":
      return <MailIcon />;
    case "navigation":
      return <NavigationIcon />;
    case "phone":
      return <PhoneIcon />;
    case "pin":
      return <PinIcon />;
    case "quote":
      return <QuoteIcon />;
    case "logo":
      return <Logo />;
    default:
      break;
  }
}
