export function toggleNavbar() {
  let previousScroll = 0;
  window.addEventListener("scroll", function() {
    let currentScroll = window.scrollY;
    let navbar = document.getElementById("navbar");
    if (currentScroll > 100) {
      navbar.classList.add("scrolled");
    } else {
      navbar.classList.remove("scrolled");
    }
    if (
      currentScroll > 0 &&
      currentScroll <
        document.documentElement.scrollHeight - this.window.innerHeight
    ) {
      if (currentScroll > previousScroll) {
        window.setTimeout(() => {
          navbar.classList.remove("visible");
          navbar.classList.add("hidden");
        }, 300);
      } else if (currentScroll + 10 < previousScroll) {
        window.setTimeout(() => {
          navbar.classList.remove("hidden");
          navbar.classList.add("visible");
        }, 300);
      }
      previousScroll = currentScroll;
    } else if (currentScroll === 0) {
      navbar.classList.remove("hidden");
      navbar.classList.add("visible");
    }
  });
}
