import React from "react";
import IntroBlock from "./content/IntroBlock";

export default function Introduction() {
  return (
    <section className=" bg-primary row m-0 second-section position-relative">
      <div className="container">
        <div className="row m-0 pt-4">
          <IntroBlock />
        </div>
      </div>
    </section>
  );
}
