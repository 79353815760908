import React, { Component } from "react";

export default class Message extends Component {
  render() {
    return (
      <aside className="message">
        {this.props.children}
      </aside>
    );
  }
}
