import {
  ROUTE_APP,
  ROUTE_CONTACT,
  ROUTE_TREATMENT,
  ROUTE_TREATMENT_DETAIL,
  route
} from "../../../core/routing/routing";

import { ReactComponent as CurveFooter } from "../../../assets/curve-footer.svg";
import { Link } from "react-router-dom";
import React from "react";
import { renderHtml } from '../../../core/helpers';
import { t } from "../../../core/localization/source";

export default function Footer() {
  return (
    <section className="row m-0 bg-primary text-light footer pt-5 mt-4">
      <CurveFooter
        preserveAspectRatio="none"
        className="footer-curve fill-primary"
      />
      <div className="container mt-3 mb-5 pb-4">
        <div className="row col-12">
          <div className="col-sm-6 col-md-4 mb-4 mb-md-0">
            <h4>{t("navigation.contact")}</h4>
            <ul>
              <li>
                <a
                  href="https://goo.gl/maps/FW6JFCAf5tPSc5w86"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-unstyled"
                >
                  {t("contact.street")}
                </a>
              </li>
              <li>
                <a
                  href={`tel:${t("contact.phone")}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-unstyled"
                >
                  {t("contact.phone")}
                </a>
              </li>
              <li>
                <a
                  href={`mailto:${t("contact.mail")}`}
                  className="link-unstyled"
                >
                  {t("contact.mail")}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm-6 col-md-3 mb-4 mb-md-0">
            <h4 dangerouslySetInnerHTML={renderHtml(t("treatments.reeducation"))}></h4>
            <ul>
              <li>
                <Link
                  className="link-unstyled"
                  to={route(ROUTE_TREATMENT_DETAIL, {
                    ":treatment": t("treatments.reeducation.route")
                  })}
                  dangerouslySetInnerHTML={renderHtml(t("treatments.reeducation"))}
                ></Link>
              </li>
              <li>
                <Link
                  className="link-unstyled"
                  to={route(ROUTE_TREATMENT_DETAIL, {
                    ":treatment": t("treatments.pregnancy.route")
                  })}
                  dangerouslySetInnerHTML={renderHtml(t("treatments.pregnancy"))}
                ></Link>
              </li>
              <li>
                <Link
                  className="link-unstyled"
                  to={route(ROUTE_TREATMENT_DETAIL, {
                    ":treatment": t("treatments.lymphe.route")
                  })}
                  dangerouslySetInnerHTML={renderHtml(t("treatments.lymphe"))}
                >
                </Link>
              </li>
              <li>
                <Link
                  className="link-unstyled"
                  to={route(ROUTE_TREATMENT_DETAIL, {
                    ":treatment": t("treatments.kine_revalidation.route")
                  })}
                  dangerouslySetInnerHTML={renderHtml(t("treatments.kine_revalidation"))}
                >
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-sm-6 col-md-2 mb-4 mb-md-0">
            <h4>{t("footer.navigation")}</h4>
            <ul>
              <li>
                <Link className="link-unstyled" to={ROUTE_APP}>
                  {t("footer.home")}
                </Link>
              </li>
              <li>
                <Link className="link-unstyled" to={ROUTE_TREATMENT}>
                  {t("footer.treatments")}
                </Link>
              </li>
              <li>
                <Link className="link-unstyled" to={ROUTE_CONTACT}>
                  {t("footer.contact")}
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-sm-6 col-md-3 mb-4 mb-md-0">
            <h4>{t("footer.number.title")}</h4>
            <ul>
              <li>{t("footer.number")}</li>
            </ul>
            <h4 className="mt-4">{t("footer.riziv.title")}</h4>
            <ul>
              <li>{t("footer.riziv")}</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
