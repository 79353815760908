import React, { Component, Fragment } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import HomeScene from "./components/home/HomeScene";
import ContactScene from "./components/contact/ContactScene";
import NoMatch from "./components/404/NoMatch";
import TreatmentDetail from "./components/treatment/detail/TreatmentDetail";
import TreatmentOverview from "./components/treatment/overview/TreatmentOverview";
import Nav from "./components/shared/nav/Nav";
import Footer from "./components/shared/footer/Footer";
import { initLocalizations } from "./core/localization/localization";
import {
  ROUTE_APP,
  ROUTE_TREATMENT,
  ROUTE_TREATMENT_DETAIL,
  ROUTE_CONTACT,
  setLanguage,
  checkRoutes,
  history
} from "./core/routing/routing";
import Message from "./components/shared/message/Message";

class App extends Component {
  constructor(props) {
    super(props);
    let language = localStorage.getItem("language");
    switch (language) {
      case "fr":
        this.state = { isDutch: false };
        initLocalizations("fr");
        setLanguage("fr");
        break;
      default:
        this.state = { isDutch: true };
        initLocalizations("nl");
        setLanguage("nl");
        break;
    }
  }

  componentDidMount() {
    fetch("/message.json")
      .then((res) => res.json())
      .then((data) => {
        this.setState({ message: data.message });
      });
  }

  toggleLang = () => {
    this.setState({ isDutch: !this.state.isDutch });
    setLanguage(this.state.isDutch ? "fr" : "nl");
    checkRoutes();
  };

  setLanguageRoute = isDutch => {
    // const currentRoute = history.location.pathname;
    if (isDutch) {
      // SET CURRENT ROUTE
    }
  };

  render() {
    return (
      <BrowserRouter>
        <Fragment>
          <Nav activePath={history.location.pathname} changeLanguage={this.toggleLang} isDutch={this.state.isDutch} />
          {
            this.state.message &&
            <Message>
              {this.state.message}
            </Message>
          }
          <Switch>
            <Route path={ROUTE_TREATMENT_DETAIL} component={TreatmentDetail} />
            <Route path={ROUTE_TREATMENT} component={TreatmentOverview} />
            <Route path={ROUTE_CONTACT} component={ContactScene} />
            <Route exact path={ROUTE_APP} component={HomeScene} />
            <Route component={NoMatch} />
          </Switch>
          <Footer />
        </Fragment>
      </BrowserRouter>
    );
  }
}

export default App;
