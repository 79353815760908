import { Localization } from "./source/index";

// Languages
import nl from "./nl";
import fr from "./fr";

// Settings Language
export const initLocalizations = (current = "nl", fallback = "nl") => {
  Localization.init({
    dictionary: {
      nl: nl,
      fr: fr
    },
    currentLocale: current,
    fallbackLocale: fallback
  });
};
