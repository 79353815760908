import { ROUTE_TREATMENT } from "../../core/routing/routing";
import React, { Fragment } from "react";

import { ReactComponent as Curve } from "../../assets/curve.svg";
import { ReactComponent as CurveSecondary } from "../../assets/curve-secondary.svg";
import FadeIn from "../shared/animations/fadeIn";
import FadeInLeft from "../shared/animations/fadeInLeft";
import FadeInUp from "../shared/animations/fadeInUp";
import Icon from "../shared/icon/Icon";
import { Link } from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";
import { t } from "../../core/localization/source";

export default function Fold() {
  return (
    <section className="min-full-height row m-0 position-relative">
      <div className="col-md-5 d-none p-0 d-md-flex justify-content-end">
        <FadeIn delay="1000">
          <div className="fold-bg-image"></div>
        </FadeIn>
        <Curve className="fill-white fold-mid-curve" />
      </div>
      <div className="offset-md-1 col-md-6 mt-md-5 pt-md-5 mb-md-5 pb-md-5 pl-5 pr-5 d-flex align-items-center justify-content-center justify-content-lg-start">
        <div className="d-flex flex-column align-self-lg-end mt-5 mb-5 pb-lg-5">
          <VisibilitySensor>
            {({ isVisible }) => (
              <Fragment>
                <FadeInUp once fadeIn={isVisible ? true : false}>
                  <h1 className="mt-5">
                    {t("home.intro.first-line")}
                    <br />
                    {t("home.intro.second-line")}
                  </h1>
                </FadeInUp>
                <div>
                  <ul className="icon-list">
                    <FadeInLeft
                      delay="500"
                      once
                      fadeIn={isVisible ? true : false}
                    >
                      <li>
                        <a
                          href={`tel:${t("contact.phone")}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="link-unstyled d-flex"
                        >
                          <span>
                            <Icon name="phone" />
                          </span>
                          <span>{t("contact.phone")}</span>
                        </a>
                      </li>
                    </FadeInLeft>
                    <FadeInLeft
                      delay="600"
                      once
                      fadeIn={isVisible ? true : false}
                    >
                      <li>
                        <a
                          href="https://goo.gl/maps/FW6JFCAf5tPSc5w86"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="link-unstyled d-flex"
                        >
                          <span>
                            <Icon name="navigation" />
                          </span>
                          <span> {t("contact.street")}</span>
                        </a>
                      </li>
                    </FadeInLeft>
                    <FadeInLeft
                      delay="700"
                      once
                      fadeIn={isVisible ? true : false}
                    >
                      <li>
                        <a
                          href={`mailto:${t("contact.mail")}`}
                          className="link-unstyled d-flex"
                        >
                          <span>
                            <Icon name="mail" />
                          </span>
                          <span> {t("contact.mail")}</span>
                        </a>
                      </li>
                    </FadeInLeft>
                  </ul>
                </div>
              </Fragment>
            )}
          </VisibilitySensor>
          <div className="d-flex flex-column flex-sm-row flex-wrap mt-3 mb-3 mb-md-5 fold-buttons">
            <Link
              to={{ pathname: "https://onlinebooking.myorganizer.online/?guid=Mjc5MDk2NmYtMDZkOC00NWMyLTk2ZDctZjlkNjdiN2Y1NmIz&label=MTE3Njk%3D&language=bmxfQkU%3D&role=OA%3D%3D" }}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-secondary d-flex align-items-center mt-3 mr-2"
            >
              {t("home.intro.button.left")}
            </Link>
            <Link
              to={ROUTE_TREATMENT}
              className="btn btn-tertiary d-flex align-items-center mt-3 mr-2"
            >
              {t("home.intro.button.right")}
            </Link>
          </div>
        </div>
      </div>
      <CurveSecondary className="fill-primary fold-bottom-curve" />
    </section>
  );
}
